<template>
    <div>
        <router-view v-if="isReportSelected"/>
        <template v-else>
            <b-row>
                <!-- NOTE FROM UAT: KEEP REPORT LISTS IN ALPHA ORDER! -->
                <b-col cols="md-6">
                    <template v-if="!!reportsForUser('applications').length">
                        <h3>Applications</h3>
                        <ul>
                            <li v-for="report in reportsForUser('applications')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="!!reportsForUser('registration').length">
                        <h3>Registration</h3>
                        <ul>
                            <li v-for="report in reportsForUser('registration')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="!!reportsForUser('travel').length">
                        <h3>Travel</h3>
                        <ul>
                            <li v-for="report in reportsForUser('travel')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="!!reportsForUser('trainees').length">
                        <h3>Trainees</h3>
                        <ul>
                            <li v-for="report in reportsForUser('trainees')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                </b-col>
                <b-col cols="md-6">
                    <template v-if="!!reportsForUser('graduation').length">
                        <h3>Graduation</h3>
                        <ul>
                            <li v-for="report in reportsForUser('graduation')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="!!reportsForUser('directory').length">
                        <h3>Directory</h3>
                        <ul>
                            <li v-for="report in reportsForUser('directory')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="!!reportsForUser('exports').length">
                        <h3>Exports</h3>
                        <ul>
                            <li v-for="report in reportsForUser('exports')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="!!reportsForUser('audit').length">
                        <h3>Audit History</h3>
                        <ul>
                            <li v-for="report in reportsForUser('audit')"
                                :key="report.toName">
                                <b-link :to="{name: report.toName, params: report.toParams}"
                                        :class="report.class">{{report.label}}</b-link>
                            </li>
                        </ul>
                    </template>
                </b-col>
            </b-row>
        </template>
    </div>
</template>


<script>

import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store/store';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import _ from 'underscore';
import {SecurityLevel} from "../../../model/security_level";

function setReportBreadcrumbs(isDetail) {
    store.commit('setBreadcrumbs', [
        Breadcrumb.create('Administration', {name: 'adminMain'}, false),
        Breadcrumb.create('Reports', {name: 'reports'}, !isDetail)
    ]);
}

function routeHandler(to, from, next) {
    setReportBreadcrumbs(to.meta.isDetail);
    next();
}


@Component
export default class Reports extends Vue {
    get isReportSelected() {
        return this.$route.meta.isDetail;
    }

    beforeRouteEnter(to, from, next) {
        routeHandler(to, from, next);
    }

    beforeRouteUpdate(to, from, next) {
        routeHandler(to, from, next);
    }

    reportsForUser(sectionName) {
        const reports = [];
        switch (sectionName) {
            case 'applications':
                reports.push(...this.applicationReports);
                break;
            case 'registration':
                reports.push(...this.registrationReports);
                break;
            case 'travel':
                reports.push(...this.travelReports)
                break;
            case 'trainees':
                reports.push(...this.traineeReports);
                break;
            case 'graduation':
                reports.push(...this.graduationReports);
                break;
            case 'directory':
                reports.push(...this.directoryReports);
                break;
            case 'exports':
                reports.push(...this.exportReports);
                break;
            case 'audit':
                reports.push(...this.auditReports);
                break;
        }
        const activeUserRole = this.activeUser.securityLevel;
        const filteredReports = _.filter(reports, r => {
            const permissionRoles = r.perms;
            return permissionRoles.includes(activeUserRole);
        });
        return filteredReports;
    }

    get applicationReports() {
        return [{
            toName: 'applicationInformation',
            label: 'Application Information',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'applicationStatistics',
            label: 'Application Statistics',
            perms: [SecurityLevel.ADMINISTRATOR]
        }];
    }

    get registrationReports() {
        return [{
            toName: 'rollSheets',
            label: 'Roll Sheets',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR]
        }, {
            toName: 'workshopCalendar',
            label: 'Workshop Calendar',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR]
        }, {
            toName: 'workshopCapacityReport',
            label: 'Workshop Capacity',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'workshopRoster',
            label: 'Workshop Roster',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'workshopSetup',
            label: 'Workshop Setup',
            perms: [SecurityLevel.ADMINISTRATOR]
        }];
    }

    get travelReports() {
        return [{
            toName: 'arrivalDeparture',
            label: 'Attendee Arrival/Departure',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR]
        }, {
            toName: 'bookedTravel',
            label: 'Booked Travel',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'missingTravel',
            label: 'Missing Travel',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR]
        }, {
            toName: 'travelRequests',
            label: 'Travel Requests',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR]
        }];
    }

    get traineeReports() {
        return [{
            toName: 'traineeStatus',
            label: 'OJT/MA Trainee Status',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR, SecurityLevel.TRAINEE]
        }, {
            toName: 'traineePlanningCalendar',
            label: 'Trainee Planning Calendar',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR, SecurityLevel.TRAINEE]
        }, {
            toName: 'traineeStatistics',
            label: 'Trainee Statistics',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'traineeTranscript',
            label: 'Trainee Transcript',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR, SecurityLevel.TRAINEE]
        }];
    }

    get graduationReports() {
        return [{
            toName: 'graduationCertificates',
            label: 'Graduation Certificates',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'graduationExport',
            label: 'Graduation Export',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'graduateRoster',
            label: 'Graduate Roster',
            perms: [SecurityLevel.ADMINISTRATOR]
        }];
    }

    get directoryReports() {
        return [{
            toName: 'mentorInstructorDirectory',
            label: 'Instructor/Mentor Directory',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR]
        }];
    }

    get exportReports() {
        return [{
            toName: 'contractorExport',
            label: 'Contractor Export',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'customOjtMaExport',
            label: 'Custom OJT/MA Export',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'ojtMaExport',
            label: 'OJT/MA Export',
            perms: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR, SecurityLevel.TRAINEE]
        }, {
            toName: 'trackExport',
            label: 'Track Export',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'userExport',
            label: 'Users Export',
            perms: [SecurityLevel.ADMINISTRATOR]
        }];
    }

    get auditReports() {
        return [{
            toName: 'auditContractorHistory',
            toParams: {},
            label: 'Contractor History',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'auditOjtMaHistory',
            toParams: {},
            label: 'OJT/MA History',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'auditUsageReport',
            toParams: {},
            label: 'Usage Report',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'auditUserHistory',
            toParams: {userType:'User'},
            label: 'User History',
            perms: [SecurityLevel.ADMINISTRATOR]
        }, {
            toName: 'vaccineRegistrationExceptionHistory',
            toParams: {userType:'User'},
            label: 'COVID-19 Registration Restriction Exception History',
            perms: [SecurityLevel.ADMINISTRATOR]
        }];
    }

    get activeUser() {
        const user = this.$store.getters['userSession/getUser'];
        return user;
    }
}
</script>


<style scoped>
    ul {
        list-style-type: circle;
    }
</style>
