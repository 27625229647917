
import {SecurityLevel} from '@/model/security_level';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';

function defineIcons(user) {

    return [{
        prefix: 'fas',
        icon: 'users',
        title: user.isAnAdministrator() ? 'Users' : (user.isAnInstructor() ? 'Mentors and Trainees' : 'Trainees'),
        to: '/admin/users',
        roles: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR]
    }, {
        prefix: 'fas',
        icon: 'clipboard-list',
        title: 'Applications',
        to: '/admin/applications',
        roles: [SecurityLevel.ADMINISTRATOR]
    }, {
        prefix: 'far',
        icon: 'address-card',
        title: 'Profile',
        to: {name: 'profileView', params: {id: user.id, activeTabIndex: 0}},
        roles: true
    }, {
        prefix: 'far',
        icon: 'calendar-check',
        title: 'Schedule',
        to: {name: 'schedule', params: {userId: user.id}},
        roles: [SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR, SecurityLevel.TRAINEE]
    }, {
        prefix: 'far',
        icon: 'calendar-alt',
        title: 'Tracks',
        to: '/admin/tracks',
        roles: [SecurityLevel.ADMINISTRATOR]
    }, {
        prefix: 'fas',
        icon: 'chalkboard-teacher',
        title: 'Instructor Sessions',
        to: 'instructor_sessions',
        roles: [SecurityLevel.ADMINISTRATOR]
    }, {
        prefix: 'fas',
        icon: 'file-download',
        title: 'Documents',
        to: {name: 'documents'},
        roles: true
    }, {
        prefix: 'fas',
        icon: 'chart-line',
        title: 'Reports',
        to: '/admin/reports',
        roles: true
    }, {
        prefix: 'fas',
        icon: 'envelope',
        title: 'Email',
        to: '/admin/email',
        roles: [SecurityLevel.ADMINISTRATOR]
    }, {
        prefix: 'fas',
        icon: 'cogs',
        title: 'Activities',
        to: {name: 'activities'},
        roles: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR]
    },
    // {
    //     prefix: 'fas',
    //     icon: 'people-carry',
    //     title: 'Custom Activities',
    //     to: {name: 'customActivities'},
    //     roles: [SecurityLevel.MENTOR]
    // },
    {
        prefix: 'fas',
        icon: 'plane',
        title: sprintf('%sTravel', user.isAMentor() || user.isAnInstructor() ? 'Registration & ' : ''),
        to: {name: 'travel', params: null},
        roles: [SecurityLevel.ADMINISTRATOR, SecurityLevel.INSTRUCTOR, SecurityLevel.MENTOR]
    }, {
        prefix: 'fas',
        icon: 'hard-hat',
        title: 'Contractors',
        to: {name: 'contractors', params: null},
        roles: [SecurityLevel.ADMINISTRATOR]
    }, {
        prefix: 'fas',
        icon: 'sliders-h',
        title: 'Preferences',
        to: {name: 'preferences'},
        roles: [SecurityLevel.ADMINISTRATOR]
    }, {
        prefix: 'far',
        icon: 'question-circle',
        title: 'Help',
        to: {name:'help'},
        roles: true
    }];
}

export const IconStore = {
    namespaced: true,

    state: {
        links: []
    },

    getters: {
        links(state) {
            return state.links;
        }
    },

    mutations: {
        setLinks(state, links) {
            state.links = links;
        }
    },

    actions: {
        compileIcons: ({commit}, user) => {

            const links = defineIcons(user);

            commit('setLinks', _.filter(links, (l) => {
                return true === l.roles || _.any(l.roles, (r) => r.equals(user.securityLevel));
            }));
        }
    }
};

export default IconStore;
